var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{style:({ maxHeight: 'calc(100vh - 88px)' }),attrs:{"no-gutters":""}},[_c('v-col',{style:({ maxHeight: 'inherit', overflowY: 'auto' }),attrs:{"cols":"6","lg":"4"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5"},[_c('b',[_vm._v("Select Your Board")])])],1)],1),_c('v-list-item',[_c('v-text-field',{attrs:{"label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider'),(this.search.trim() && !_vm.searchBoards.length)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("No boards found that match that search")])],1)],1):_vm._e(),(!_vm.cores.length)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" No board data loaded, try "),_c('router-link',{attrs:{"to":"/tools/servers"}},[_vm._v("selecting a server")]),_vm._v(" to load it in. ")],1)],1)],1):_vm._e(),_vm._l((_vm.searchBoards),function(board){return _c('v-list-item',{key:board.uuid,attrs:{"input-value":_vm.currentBoard && _vm.currentBoard.uuid === board.uuid,"two-line":""},on:{"click":function($event){return _vm.setCurrent(board)}}},[_c('v-list-item-content',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item-title',_vm._g({class:_vm.currentBoard && _vm.currentBoard.uuid === board.uuid
                    ? 'primary--text' : undefined},on),[_vm._v(" "+_vm._s(board.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(board.name))])]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s((_vm.cores.find(function (core) { return board.fqbn.includes(((core.coreId) + ":")); }) || { name: '' }).name)+" ")])],1)],1)}),_vm._l((this.search.trim() ? [] : _vm.cores),function(core){return _c('v-list-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.findBoards({
          query: { fqbn: new RegExp(("^" + (core.coreId) + ":")), supported: true },
        }).total),expression:"findBoards({\n          query: { fqbn: new RegExp(`^${core.coreId}:`), supported: true },\n        }).total"}],key:core.uuid,attrs:{"value":_vm.currentBoard && _vm.currentBoard.fqbn.includes(((core.coreId) + ":"))},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{class:_vm.currentBoard && _vm.currentBoard.fqbn.includes(((core.coreId) + ":"))
              ? 'primary--text'
              : undefined},[_vm._v(" "+_vm._s(core.name)+" ")])]},proxy:true}],null,true)},_vm._l((_vm.findBoards({
            query: { fqbn: new RegExp(("^" + (core.coreId) + ":")), supported: true },
          }).data),function(board){return _c('v-list-item',{key:board.uuid,attrs:{"input-value":_vm.currentBoard && _vm.currentBoard.uuid === board.uuid},on:{"click":function($event){return _vm.setCurrent(board)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-list-item-title',_vm._g({staticClass:"pl-4"},on),[_vm._v(_vm._s(board.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(board.name))])])],1)}),1)})],2)],1),_c('v-col',{style:({ maxHeight: 'inherit', overflowY: 'auto' }),attrs:{"cols":"6","lg":"8"}},[(_vm.currentBoard)?_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.currentBoard.name)+" Settings ")]),(!_vm.currentBoard.config_options)?_c('v-card-text',[_vm._v(" This board has no configuration options. ")]):_c('v-card-text',[_c('v-row',_vm._l((_vm.currentBoard.config_options),function(config,i){return _c('v-col',{key:i,attrs:{"cols":"12","lg":"4"}},[_c('v-select',{attrs:{"items":config.values,"value":(config.values.find(function (val) { return val.selected; }) || {}).value || null,"label":config.option_label,"item-text":"value_label"},on:{"input":function($event){return _vm.updateValue(i, $event)}}})],1)}),1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }